import { supabase } from "../../../../../supabase";

export const getAgentStructureReport = async (
  dealerCode,
  supplier,
  startDate,
  endDate
) => {
  let returnData = [];
  const { data: structureReport, error } = await supabase.rpc(
    "get_structure_report",
    {
      dealer_code_param: dealerCode,
      start_date_param: startDate,
      end_date_param: endDate,
      supplier_param: supplier,
    }
  );
  if (error) {
    console.error(error);
    returnData = error;
  } else {
    returnData = structureReport;
  }
  return returnData;
};
