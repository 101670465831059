// #useAuthUpdated
import {
  Button,
  Grid,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { pageTitleStyles } from "../../../../mui/styles";
import MobileLeaderBoard from "./MobileLeaderBoard";
import OverallDesk from "./OverallDesk";
import StructureDesk from "./StructureDesk";
import { useAuth } from "../../../../contexts/AuthContext";
import { useCompany } from "../../../../contexts/CompanyContext";
import { useSB } from "../../../../contexts/SupabaseContext";
import { supabase } from "../../../../supabase";
import Loading from "../../../Loading";

export default function LeaderBoard() {
  const [selectedButton, setSelectedButton] = useState("Overall");
  const { GetTableWhere } = useSB();
  const { suppliers } = useCompany();
  const [leaders, setLeaders] = useState([]);
  const [structureData, setStructureData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const currentYM = moment().format("YYYY-MM");
  const [selectedSuppliers, setSelectedSuppliers] = useState("all");
  const [downstream, setDownstream] = useState([{}]);
  const [leaderboardDate, setLeaderboardDate] = useState(currentYM);
  const start_d = `${leaderboardDate}-01`;
  const end_d = moment(start_d).endOf("month").format("YYYY-MM-DD");

  // Effect hook to fetch downstream data (e.g., users associated with the current dealer)
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetTableWhere("dealer_downstream", [
          "dealer_id",
          "eq",
          currentUser.id,
        ]);
        setDownstream(data[0].downstream_uids);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [leaderboardDate, selectedSuppliers]);

  // Effect hook to fetch leaderboard data from Supabase
  useEffect(() => {
    async function fetchLeaders() {
      setLoading(true);
      const query = supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("leaderboard_v2")
        .select(
          "*, dealers(contact_name, contact_surname, user_type), suppliers(supplier)"
        )
        .gte("start_d", start_d)
        .lte("start_d", end_d)
        .order("sales", { ascending: false });
      if (selectedSuppliers !== "all") {
        query.eq("supplier", selectedSuppliers);
      }

      const { data, error } = await query;
      if (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        return;
      }

      // Filters out business users
      const users = data.filter(item => {
        if (item.dealer_id !== null) {
          return item.dealers.user_type !== 2;
        }
        return false;
      });

      // Fillters out dealers with no sales and sorts by sales
      const sortedLeaders = users
        .filter(item => item.dealer_id)
        .reduce((result, item) => {
          const existing = result.find(r => r.dealer_id === item.dealer_id);
          if (existing) existing.sales += item.sales || 0;
          else result.push(item);
          return result;
        }, [])
        .sort((a, b) => b.sales - a.sales);

      // Adds rank and percentage
      const leaderData = sortedLeaders.map((item, index) => ({
        ...item,
        rank: index,
        p: ((item.sales / sortedLeaders[0].sales) * 100).toFixed(2),
      }));

      setLeaders(leaderData);
      setLoading(false);
    }

    fetchLeaders();
  }, [leaderboardDate, selectedSuppliers]);

  // Effect hook to structure leaderboard data based on downstream IDs
  useEffect(() => {
    const filteredLeaders = leaders.filter(leader =>
      downstream.toString().includes(leader.dealer_id)
    );
    setStructureData(filteredLeaders);
    setLoading(false);
  }, [downstream, leaders, selectedSuppliers]);

  // Function to handle date navigation buttons for changing leaderboard months
  const handleDateButtons = direction => {
    setLoading(true);
    const newDate = moment(leaderboardDate)
      .add(direction === ">" ? 1 : -1, "month")
      .format("YYYY-MM");
    setLeaderboardDate(newDate);
    setLoading(false);
  };

  // Handlers for changing supplier and option selections
  const handleChangeSuppliers = e => setSelectedSuppliers(e.target.value);
  const handleClick = id => setSelectedButton(id);

  return (
    <div>
      {!loading ? (
        <>
          <Typography
            variant="h4"
            style={{ ...pageTitleStyles, fontWeight: "550" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={1} md={3}>
                <Box
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                    flex: 1,
                  }}
                >
                  Leaderboard
                </Box>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-end",
                  }, // Align horizontally
                  flex: 1,
                }}
              >
                {" "}
                <Grid
                  container
                  xs={10}
                  sm={12}
                  md={11}
                  spacing={1}
                  alignContent="left"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: 6, width: "100%" }}
                    >
                      <InputLabel
                        style={{
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        Select a Supplier
                      </InputLabel>
                      <Select
                        label="Select a Supplier"
                        value={selectedSuppliers}
                        onChange={handleChangeSuppliers}
                        style={{
                          height: 38,
                          marginTop: -2,
                          fontSize: "12px",
                        }}
                      >
                        <MenuItem value="all">All Suppliers</MenuItem>
                        {suppliers.map(supplier => {
                          return supplier.leaderboard &&
                            (supplier.supplier_status === "Active" ||
                              supplier.supplier_status === "Testing") ? (
                            <MenuItem value={supplier.id} key={supplier.id}>
                              {supplier.supplier}
                            </MenuItem>
                          ) : null;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                          sm: null,
                        }, // Align horizontally

                        flex: 1,
                      }}
                    >
                      <Grid container xs={12} sm={12} direction="row">
                        <Grid item xs={6} style={{ paddingRight: ".5rem" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons("<")}
                            fullWidth
                          >
                            PREV
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons(">")}
                            fullWidth
                            disabled={leaderboardDate === currentYM}
                          >
                            NEXT
                          </Button>
                        </Grid>
                      </Grid>{" "}
                    </Box>
                  </Grid>
                  {/* // structure options */}
                  {currentUser.rank !== 8 ? (
                    <Hidden only={["xs"]}>
                      <Grid item xs={12} sm={12} md={4}>
                        <Grid container direction="row" spacing={1}>
                          <Hidden mdUp>
                            <Grid
                              item
                              style={{
                                paddingRight: "1rem",
                              }}
                            />
                          </Hidden>
                          <Grid
                            item
                            xs={6}
                            sm={5}
                            md={6}
                            style={{
                              paddingRight: ".5rem",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                color:
                                  selectedButton === "Overall"
                                    ? "white"
                                    : "GrayText",
                                backgroundColor:
                                  selectedButton === "Overall"
                                    ? "#3D7155"
                                    : "lightGray",
                              }}
                              fullWidth
                              onClick={() => handleClick("Overall")}
                            >
                              Overall
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={5} md={6}>
                            {structureData !== undefined ? (
                              <Button
                                variant="contained"
                                style={{
                                  color:
                                    selectedButton === "Structure"
                                      ? "white"
                                      : "GrayText",
                                  backgroundColor:
                                    selectedButton === "Structure"
                                      ? "#F0BD44"
                                      : "lightGray",
                                }}
                                fullWidth
                                onClick={() => handleClick("Structure")}
                              >
                                Structure
                              </Button>
                            ) : (
                              <Button
                                disabled
                                variant="contained"
                                style={{
                                  color:
                                    selectedButton === "Structure"
                                      ? "white"
                                      : "GrayText",
                                  backgroundColor:
                                    selectedButton === "Structure"
                                      ? "#F0BD44"
                                      : "lightGray",
                                }}
                                fullWidth
                              >
                                Structure
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                  ) : (
                    <div />
                  )}
                </Grid>{" "}
              </Box>
            </Grid>
          </Typography>
          {leaders.length === 0 ? (
            <Alert
              severity="warning"
              style={{
                borderRadius: "8px",
                paddingTop: { xs: "-2", sm: "0" },
              }}
            >
              Please check back later to see your performance for
              <Typography variant="inherit" style={{ fontWeight: "500" }}>
                {" "}
                {leaderboardDate}{" "}
              </Typography>
              or click "previous" to see last month's leaderboard.
            </Alert>
          ) : (
            <div>
              {leaders.length > 0 ? (
                <>
                  {" "}
                  <Alert
                    severity="info"
                    style={{
                      borderRadius: "8px",

                      marginBottom: "1rem",
                      marginTop: "-0.5rem",
                    }}
                  >
                    Leaderboard for: {leaderboardDate}
                  </Alert>
                  <Hidden only={["xs"]}>
                    {selectedButton === "Overall" ? (
                      <OverallDesk leaders={leaders} />
                    ) : (
                      <StructureDesk
                        leaders={leaders}
                        structureData={structureData}
                      />
                    )}
                  </Hidden>
                  <Hidden only={["xl", "lg", "md", "sm"]}>
                    <MobileLeaderBoard
                      leaders={leaders}
                      structureData={structureData}
                    />
                  </Hidden>
                </>
              ) : (
                <h1>
                  The Leaderboard has not been updated for this month yet.
                </h1>
              )}
            </div>
          )}
        </>
      ) : (
        <div>
          <Loading loading={loading} />
        </div>
      )}
    </div>
  );
}
