import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DesktopUserCard from "./DesktopUserCard";
import Top3UserCard from "./Top3UserCard";
import { useAuth } from "../../../../contexts/AuthContext";

export default function OverallDesk({ leaders }) {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [bigCardSize, setBigCardSize] = useState(12);
  const [data, setData] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (leaders === undefined) {
      // Nothing
    } else {
      setLoading(false);
      setData(leaders.sort((a, b) => a.id - b.id));
    }
    if (leaders.length === 1) {
      setBigCardSize(12);
    } else if (leaders.length === 2) {
      setBigCardSize(6);
    } else if (leaders.length > 2) {
      setBigCardSize(4);
    }
  }, [leaders]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const cu = currentUser.dealer_code;

  return (
    <div>
      {loading ? (
        <h1>loading...</h1>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={bigCardSize}>
                  <Top3UserCard
                    name={`${data[0]?.dealers?.contact_name} ${data[0]?.dealers?.contact_surname}`}
                    avatar="1"
                    percentage={data[0]?.p}
                    fiber={data[0]?.sales}
                    rank="https://www.svgrepo.com/show/404692/1st-place-medal.svg"
                    colored="#FFD700"
                    borderColor="#F0BD44"
                  />
                </Grid>
                {data[1] ? (
                  <Grid item xs={bigCardSize}>
                    <Top3UserCard
                      name={`${data[1]?.dealers?.contact_name} ${data[1]?.dealers?.contact_surname}`}
                      avatar="1"
                      percentage={data[1]?.p}
                      fiber={data[1]?.sales}
                      rank="https://www.svgrepo.com/show/404693/2nd-place-medal.svg"
                      colored="#FFD700"
                      borderColor="#F0BD44"
                    />
                  </Grid>
                ) : null}{" "}
                {data[2] ? (
                  <Grid item xs={bigCardSize}>
                    <Top3UserCard
                      name={`${data[2]?.dealers?.contact_name} ${data[2]?.dealers?.contact_surname}`}
                      avatar="1"
                      percentage={data[2]?.p}
                      fiber={data[2]?.sales}
                      rank="https://www.svgrepo.com/show/404695/3rd-place-medal.svg"
                      colored="#FFD700"
                      borderColor="#F0BD44"
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {data.slice(3, 10).map(Leader => {
                    return (
                      <Grid item xs={12} key={Leader.id}>
                        <DesktopUserCard
                          rank={Number(Leader.rank) + 1}
                          avatar="https://mui.com//static/images/avatar/4.jpg"
                          name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                          percentage={Leader.p}
                          fiber={Leader.sales}
                          outlined={
                            Leader.dealer_code === cu ? "solid" : "none"
                          }
                        />
                      </Grid>
                    );
                  })}
                  {data.length > 10 ? (
                    <Grid item xs={12}>
                      <Card>
                        {" "}
                        <Button
                          fullWidth
                          onClick={() => handleExpand()}
                          style={{ padding: 15 }}
                        >
                          <Typography
                            align="center"
                            variant="h3"
                            color="primary"
                          >
                            Expand
                            {!expanded ? (
                              <KeyboardArrowDown
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            ) : (
                              <KeyboardArrowUp
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            )}
                          </Typography>{" "}
                        </Button>
                      </Card>
                    </Grid>
                  ) : null}
                  {expanded
                    ? data.slice(10, 100).map(Leader => {
                        return (
                          <Grid item xs={12} key={Leader.id}>
                            <DesktopUserCard
                              rank={
                                Leader.sales !== 0 ? Leader.rank + 1 : " --"
                              }
                              avatar="https://mui.com//static/images/avatar/4.jpg"
                              name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                              percentage={Leader.p}
                              fiber={Leader.sales}
                              outlined={
                                Leader.dealer_code === cu ? "solid" : "none"
                              }
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
