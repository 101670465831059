/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import MessageBubble from "./MessageBubble";
import { useSB } from "../../../../contexts/SupabaseContext";
import { useCompany } from "../../../../contexts/CompanyContext";

export default function LogASale(props) {
  const { GetTableWhere, updateRow, insertRow } = useSB();
  const { supplier } = props;
  const { currentUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const currentDate = new Date();
  const [selectedMessage, setSelectedMessage] = useState({
    agent_note: "",
    ref_number: "",
    old_agent_note: "",
    id: "",
  });
  const [orderId, setOrderId] = useState("");
  const [note, setNote] = useState("");
  const [logs, setLogs] = useState([
    {
      agent_code: "Loading...",
      ref_number: "Loading...",
      supplier: "Loading...",
      claim_timestamp: { value: "Loading..." },
      agent_note: null,
    },
  ]);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isIdValid, setIsIdValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [delW, setDelW] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const { suppliers } = useCompany();

  // Handle snackbar close event
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Show snackbar with message and severity
  const showSnackbar = (message, severity) => {
    setIsIdValid(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setIsIdValid(false);
    setSnackbarOpen(true);
  };

  // Function to log a sale
  const Run = async () => {
    let pattern = supplier.regex;

    pattern = new RegExp(pattern);

    if (supplier.logSalesName) {
      setIsIdValid(false);
      showSnackbar(
        "There has been an error, please contact support. Code: 411",
        "error"
      );
      setLoading(false);
      return;
    }

    if (pattern) {
      const result = pattern.test(orderId);

      if (!result) {
        setIsIdValid(false);
        showSnackbar(
          "Reference number does not follow the correct format.",
          "error"
        );
        setLoading(false);
        return;
      }
    }

    // eslint-disable-next-line no-unreachable
    setLoading(true);

    if (!isIdValid) {
      showSnackbar("Please enter a reference number.", "error");
      setLoading(false);
      return;
    }
    async function addData() {
      try {
        const data = await insertRow("agent_order_claim", {
          agent_code: `${currentUser.dealer_code}`,
          agent_id: `${currentUser.id}`,
          ref_number: `${orderId}`,
          supplier: props.supplier.supplier,
          supplier_id: suppliers.find(
            s => s.supplier === props.supplier.supplier
          )?.id,
          agent_note: `${note}`,
          claim_timestamp: currentDate,
        });
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const response = await addData();

    if (response.error) {
      showSnackbar(response.error, "error");
    } else {
      setNote("");
      setOrderId("");
      showSnackbar("Sale logged successfully!", "success");
    }

    setLoading(false);
    setIsIdValid(false);
    return response;
  };

  // Function to fetch logs
  const getLogsReq = async () => {
    setLoadingLogs(true);

    async function fetchData() {
      try {
        const data = await GetTableWhere(
          "agent_order_claim",
          ["agent_id", "eq", `${currentUser.id}`],
          ["supplier_id", "eq", props.supplier.id],
          ["deleted", "eq", false]
        );
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const data = await fetchData();
    setLoadingLogs(false);
    return data;
  };

  // Fetch logs on component mount or when loading or selectedMessage changes
  useEffect(() => {
    getLogsReq().then(result => {
      setLogs([result]);
    });
  }, [loading, selectedMessage]);

  // Open modal for editing log
  const openModal = message => {
    setSelectedMessage(message);
    setModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setSelectedMessage({
      agent_note: "",
      ref_number: "",
      old_agent_note: "",
      id: "",
    });
    setModalLoading(false);
    setModalOpen(false);
  };

  // Delete a log
  const deleteLog = async id => {
    setModalLoading(true);
    async function deleteData() {
      try {
        await updateRow("agent_order_claim", id.id, {
          deleted: true,
        });
        return true;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const response = await deleteData();
    if (response) {
      showSnackbar("Sale deleted successfully!", "error");
      setModalLoading(false);
      setSelectedMessage({
        agent_note: "",
        ref_number: "",
        old_agent_note: "",
        id: "",
      });
      setDelW(false);
      closeModal();
    } else {
      showSnackbar("Error deleting the sale. Please try again.", "error");
      closeModal();
    }
    return null;
  };

  // Open delete warning modal
  const OpenDelWarning = () => {
    setDelW(true);
  };

  // Close delete warning modal
  const CloseDelWarning = () => {
    setDelW(false);
  };

  // Edit a log
  const editLog = async () => {
    setModalLoading(true);
    async function editData() {
      try {
        const data = await updateRow("agent_order_claim", selectedMessage.id, {
          agent_note: `${selectedMessage.agent_note}`,
        });
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const response = await editData();
    if (response.status === 204) {
      showSnackbar("Sale edited successfully!", "success");
      setModalLoading();
      setSelectedMessage({
        agent_note: "",
        ref_number: "",
        old_agent_note: "",
        id: "",
      });
      closeModal();
    } else {
      showSnackbar("Error editing the sale. Please try again.", "error");
      closeModal();
    }

    return null;
  };

  return (
    <>
      {" "}
      <Alert severity="warning">
        "Please Note: that this tool is no longer used to log a sale. This is a
        tool for you to keep notes of your sales."
      </Alert>
      <Grid
        container
        xs={12}
        spacing={2}
        style={{
          width: { xs: "100%", md: "60%" },
          margin: "0 auto",
          marginTop: "1rem",
        }} // Centering the Grid container
        justifyContent="center" // Centering content horizontally
        alignItems="center" // Centering content vertically
      >
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  style={{
                    fontSize: "1.75rem",
                    fontWeight: "400",
                    color: "#000",
                    padding: "20px",
                  }}
                >
                  Sales notes
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CardHeader
                  subheader={
                    <TextField
                      disabled={loading}
                      placeholder="Reference Number"
                      variant="outlined"
                      required
                      fullWidth
                      value={orderId}
                      onChange={e => setOrderId(e.target.value)}
                      onBlur={e => setIsIdValid(e.target.value.trim() !== "")}
                      error={!isIdValid}
                    />
                  }
                />
                <CardHeader
                  subheader={
                    <TextField
                      disabled={loading}
                      placeholder="Agent note"
                      variant="outlined"
                      fullWidth
                      value={note}
                      onChange={e => setNote(e.target.value)}
                    />
                  }
                />
                <CardActions>
                  <Grid
                    container
                    justify="flex-end"
                    style={{ paddingBottom: "20px" }}
                  >
                    <Button
                      style={{
                        width: "100%",
                        margin: "10px",
                      }}
                      disabled={loading || !isIdValid}
                      color="primary"
                      variant="contained"
                      onClick={() => Run()}
                    >
                      Log Sale
                    </Button>
                  </Grid>
                </CardActions>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {logs[0]?.length > 0 ? (
          <>
            <Grid xs={12} style={{ margin: 20 }}>
              <Typography variant="h5" align="center">
                Your Sales
              </Typography>
            </Grid>
            {logs &&
              logs[0]?.map(mes => (
                <Button
                  onClick={() => {
                    openModal({
                      agent_note: mes.agent_note,
                      old_agent_note: mes.agent_note,
                      ref_number: mes.ref_number,
                      id: mes.id,
                    });
                  }}
                  fullWidth
                  style={{ padding: 0, margin: 0 }}
                >
                  <MessageBubble
                    key={mes.id}
                    message={mes.agent_note}
                    timestamp={mes.claim_timestamp.slice(0, 10)}
                    RefNum={mes.ref_number}
                  />
                </Button>
              ))}
          </>
        ) : (
          <Grid xs={12}>
            <Grid xs={12} style={{ margin: 20 }}>
              {loadingLogs ? (
                <Typography variant="h5" align="center">
                  Loading...
                </Typography>
              ) : (
                <Typography variant="h5" align="center">
                  No sales to list
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Modal
        open={modalOpen}
        onClose={!modalLoading ? closeModal : ""}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Grid
            item
            xs={12}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              minWidth: "300px",
            }}
          >
            {!delW ? (
              <Card style={{ padding: 20 }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      variant="h4"
                      color={`${modalLoading ? "textSecondary" : "primary"}`}
                    >
                      {!modalLoading ? " Edit Sales " : "Loading..."}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CardHeader
                      subheader={
                        <TextField
                          disabled={modalLoading}
                          placeholder={selectedMessage.agent_note}
                          variant="outlined"
                          fullWidth
                          value={selectedMessage.agent_note}
                          onChange={e => {
                            setSelectedMessage(prevState => ({
                              ...prevState,
                              agent_note: e.target.value,
                            }));
                          }}
                        />
                      }
                    />
                    <CardActions>
                      <Grid container justify="flex-end" spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            style={{
                              width: "100%",
                            }}
                            disabled={modalLoading}
                            color="primary"
                            variant="contained"
                            onClick={() => editLog()}
                          >
                            Edit Sale
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            style={{
                              width: "100%",
                              color: "white",
                              background: "#C70000",
                            }}
                            disabled={modalLoading}
                            color="secondary"
                            variant="contained"
                            onClick={() => OpenDelWarning()}
                          >
                            Delete sale
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <Card style={{ padding: 20 }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      variant="h4"
                      color={`${modalLoading ? "textSecondary" : "primary"}`}
                    >
                      {!modalLoading ? "Warning" : "Loading..."}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CardHeader
                      subheader={
                        <Typography align="center" color="error">
                          Are you sure you want to remove this log?
                        </Typography>
                      }
                    />
                    <CardActions>
                      <Grid container justify="flex-end" spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            style={{
                              width: "100%",
                            }}
                            disabled={modalLoading}
                            color="primary"
                            variant="contained"
                            onClick={() => CloseDelWarning()}
                          >
                            NO
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            style={{
                              width: "100%",
                              background: "#C70000",
                            }}
                            disabled={modalLoading}
                            variant="contained"
                            onClick={() => deleteLog(selectedMessage)}
                          >
                            YES
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Fade>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
