import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Slider } from "@mui/material";
import { Alert } from "@material-ui/lab";
import { pageTitleStyles } from "../../../mui/styles";
import { useCompany } from "../../../contexts/CompanyContext";
import BackLink from "../../shared/BackLink";
import SupplierFormValidation from "./SupplierFormValidation";
import AddBanner from "../Banner/AddBanner";

export default function EditSupplier({ match }) {
  const defFormValues = {
    supplier: "",
    internal_identifier: "",
    supplier_description: "",
    supplier_status: "",
    admin_contact_person: "",
    admin_contact_number: "",
    admin_contact_email: "",
    finance_contact_person: "",
    finance_contact_number: "",
    finance_contact_email: "",
    data_contact_person: "",
    data_contact_number: "",
    data_contact_email: "",
    it_contact_person: "",
    it_contact_number: "",
    it_contact_email: "",
    regex: "",
    log_sales: false,
    log_sales_name: "",
    reports: false,
    reports_name: "",
    reports_display_name: "",
    custom_page: false,
    custom_page_url: "",
    logo: "",
    logo_size: "100",
    weighting_opportunity: 0,
    weighting_customer_service: 0,
    sales_assist: false,
    sales_assist_text: "",
    agent_assist: false,
    agent_assist_text: "",
    leaderboard: false,
  };

  const history = useHistory();
  const { getSupplier, upsertSupplier } = useCompany();
  const supplier = match.params.id ? getSupplier(match.params.id) : null;
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState(defFormValues);
  const [banner, setBanner] = useState({ images: [] });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    setLoading(true);
    if (supplier) setFormValues(await supplier);
    setLoading(false);
  }, []);

  const convertSupplierName = string => {
    const lowercaseString = string.toLowerCase();

    const resultString = lowercaseString.replace(/ /g, "_");

    return resultString;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "reports") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.reports,
      }));
    } else if (name === "log_sales") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.log_sales,
      }));
    } else if (name === "custom_page") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.custom_page,
      }));
    } else if (name === "supplier") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
        internal_identifier: convertSupplierName(value),
      }));
    } else if (name === "sales_assist") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.sales_assist,
      }));
    } else if (name === "agent_assist") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agent_assist,
      }));
    } else if (name === "leaderboard") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.leaderboard,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const handleSliders = event => {
    const { name, value } = event.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value / 100,
    }));
  };

  // UX marks on sliders

  const sliderMarks = [
    { value: 0, label: "0%" },
    { value: 100, label: "100%" },
  ];

  const handleEditSupplier = async () => {
    SupplierFormValidation(formValues)
      .then(async () => {
        await upsertSupplier(formValues);
        if (supplier) {
          history.push(`/admin/Supplier/${match.params.id}`);
        } else {
          history.push("/settings/suppliers/");
        }
      })
      .catch(errorMessage => {
        setError(errorMessage);
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Banner</DialogTitle>
        <DialogContent>
          <AddBanner
            open={open}
            close={handleClose}
            banner={banner}
            setBanner={setBanner}
            folder={match.params.id}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <BackLink />
      <h1 style={pageTitleStyles}>
        {supplier ? "Edit Supplier" : "Add Supplier"}
      </h1>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Supplier"
              placeholder="Supplier"
              variant="outlined"
              required
              name="supplier"
              value={formValues.supplier}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Internal Identifier"
              placeholder="Internal Identifier (eg: 'sadv_fibre')"
              variant="outlined"
              required
              name="internal_identifier"
              value={formValues.internal_identifier}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Supplier Description"
              variant="outlined"
              required
              name="supplier_description"
              value={formValues.supplier_description}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Logo Url"
              variant="outlined"
              required
              name="logo"
              value={formValues.logo}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Logo Size"
              variant="outlined"
              required
              name="logo_size"
              value={formValues.logo_size}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="supplier-status-label">
                Supplier Status
              </InputLabel>
              <Select
                value={formValues.supplier_status}
                fullWidth
                variant="outlined"
                required
                name="supplier_status"
                onChange={handleChange}
                label="Supplier Status"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Testing">Testing</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography id="continuous-slider" gutterBottom>
                Opportunity Rating
              </Typography>
              <Slider
                name="weighting_opportunity"
                value={formValues.weighting_opportunity * 100}
                aria-label="weighting_opportunity"
                onChange={handleSliders}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={10}
                marks={sliderMarks}
              />
            </Grid>{" "}
            <Grid item xs={12} md={6}>
              <Typography id="continuous-slider" gutterBottom>
                Customer Service Rating
              </Typography>
              <Slider
                name="weighting_customer_service"
                value={formValues.weighting_customer_service * 100}
                aria-label="weighting_customer_service"
                onChange={handleSliders}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={10}
                marks={sliderMarks}
              />
            </Grid>{" "}
          </Grid>
          <Grid item xs={12}>
            <Typography>Admin Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Admin Contact Person"
              variant="outlined"
              name="admin_contact_person"
              required
              value={formValues.admin_contact_person}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Admin Contact Number"
              variant="outlined"
              name="admin_contact_number"
              required
              value={formValues.admin_contact_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Admin Contact Email"
              variant="outlined"
              name="admin_contact_email"
              required
              value={formValues.admin_contact_email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Finance Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Finance Contact Person"
              variant="outlined"
              name="finance_contact_person"
              required
              value={formValues.finance_contact_person}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Finance Contact Number"
              variant="outlined"
              name="finance_contact_number"
              required
              value={formValues.finance_contact_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Finance Contact Email"
              variant="outlined"
              name="finance_contact_email"
              required
              value={formValues.finance_contact_email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Data Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Data Contact Person"
              variant="outlined"
              name="data_contact_person"
              required
              value={formValues.data_contact_person}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Data Contact Number"
              variant="outlined"
              required
              name="data_contact_number"
              value={formValues.data_contact_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Data Contact Email"
              variant="outlined"
              name="data_contact_email"
              required
              value={formValues.data_contact_email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>IT Contact</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="IT Contact Person"
              variant="outlined"
              name="it_contact_person"
              required
              value={formValues.it_contact_person}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="IT Contact Number"
              variant="outlined"
              name="it_contact_number"
              required
              value={formValues.it_contact_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="IT Contact Email Address"
              variant="outlined"
              name="it_contact_email"
              required
              value={formValues.it_contact_email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Order Number Regex Value"
              variant="outlined"
              name="regex"
              value={formValues.regex}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add Banner
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Leaderboard"
              name="leaderboard"
              checked={formValues.leaderboard}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Sales Notes"
              name="log_sales"
              checked={formValues.log_sales}
              onChange={handleChange}
            />
          </Grid>

          {formValues.log_sales === true ? (
            <Grid item xs={12}>
              <TextField
                label="log Sales Name"
                variant="outlined"
                name="log_sales_name"
                required
                value={formValues.log_sales_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          ) : (
            <div />
          )}
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Reports"
              name="reports"
              checked={formValues.reports}
              onChange={handleChange}
            />
          </Grid>
          {formValues.reports === true ? (
            <Grid item xs={12}>
              <TextField
                label="ReportsName"
                variant="outlined"
                name="reports_name"
                required
                value={formValues.reports_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          ) : (
            <div />
          )}
          {formValues.reports === true ? (
            <Grid item xs={12}>
              <TextField
                label="Reports Display Name"
                variant="outlined"
                name="reports_display_name"
                required
                value={formValues.reports_display_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          ) : (
            <div />
          )}

          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Custom page"
              name="custom_page"
              checked={formValues.custom_page}
              onChange={handleChange}
            />
          </Grid>
          {formValues.custom_page === true ? (
            <Grid item xs={12}>
              <TextField
                label="Custom Page Url"
                variant="outlined"
                name="custom_page_url"
                required
                value={formValues.custom_page_url}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          ) : (
            <div />
          )}
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Sales Assist"
              name="sales_assist"
              checked={formValues.sales_assist}
              onChange={handleChange}
            />
          </Grid>
          {formValues.sales_assist && (
            <Grid item xs={12}>
              <TextField
                label="Sales Assist front end display notes (MD)"
                variant="outlined"
                name="sales_assist_text"
                value={formValues.sales_assist_text}
                onChange={handleChange}
                multiline
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox required />}
              label="Agent Assist"
              name="agent_assist"
              checked={formValues.agent_assist}
              onChange={handleChange}
            />
          </Grid>
          {formValues.agent_assist && (
            <Grid item xs={12}>
              <TextField
                label="Agent Assist front end display notes (MD)"
                variant="outlined"
                name="agent_assist_text"
                value={formValues.agent_assist_text}
                onChange={handleChange}
                multiline
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleEditSupplier}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}
