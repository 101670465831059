import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSB } from "../../contexts/SupabaseContext";

/**
 * This component is used to select suppliers for a dealer.
 * It takes in a products array, a suppliers array, and a productTypes array.
 * It also takes in a dealerState object which has a parentDealer property which is either an object or a dealer code.
 * The component will filter the products array based on the selected product types
 * and then filter the suppliers array based on the products array.
 * The component will also filter the suppliers array based on the selected suppliers of the parent dealer.
 * @param {Object} props
 * @param {Array} props.products - An array of product objects
 * @param {Array} props.suppliers - An array of supplier objects
 * @param {Array} props.productTypes - An array of product type objects
 * @param {Object} props.dealerState - An object containing the dealer state
 * @param {function} props.onChangeProductTypes - A function to call when the selected product types change
 * @param {function} props.onChangeSuppliers - A function to call when the selected suppliers change
 * @param {boolean} props.editing - A boolean indicating whether the component is being used for editing or not
 * @param {Object} props.currentUser - An object containing the current user's state
 * @param {function} props.setParentSelectedSuppliersState - A function to call when the component needs to update the parent dealer's selected suppliers
 */
export default function SelectSuppliers(props) {
  const {
    products,
    suppliers,
    productTypes,
    dealerState,
    onChangeProductTypes,
    onChangeSuppliers,
    editing,
    currentUser,
    setParentSelectedSuppliersState,
  } = props;
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [noParentSupplierNote, setNoParentSupplierNote] = useState("");
  const { GetTableWhere } = useSB();

  // filteredProductTypes is the product types that are available to the dealer based on their parent.
  const [filteredProductTypes, setFilteredProductTypes] =
    useState(productTypes);

  const getDealerDetails = dealerId => {
    const queryField = dealerId.length > 10 ? "id" : "dealer_code";
    return GetTableWhere("dealers", [queryField, "eq", dealerId]).then(
      data => data[0]
    );
  };

  const handleProductTypeChange = event => {
    const { value } = event.target;
    setSelectedProductTypes(prevSelected => {
      const updatedSet = new Set(prevSelected);
      if (updatedSet.has(value)) {
        updatedSet.delete(value); // Remove if it exists
      } else {
        updatedSet.add(value); // Add if it doesn't exist
      }
      return Array.from(updatedSet);
    });
  };

  /**
   * Fetches the parent dealers product types and filters them based on if the parent dealer has access to it.
   * Also filters the product types to only include the ones that are active.
   * @param {Object} dealerState - The state of the dealer that is being edited.
   * @param {Array} productTypes - An array of product types.
   */
  useEffect(() => {
    if (!dealerState.parentDealer) {
      const filteredProductTypesForDealer = productTypes.filter(type =>
        currentUser.selected_product_types?.includes(type.id)
      );

      setFilteredProductTypes(filteredProductTypesForDealer);
      return;
    }

    if (typeof dealerState.parentDealer === "object") {
      if (dealerState.parentDealer.rank === 1 || currentUser.rank === 1) {
        setFilteredProductTypes(productTypes);
        return;
      }

      const filteredProductsObject = [];

      dealerState.parentDealer.value?.selected_product_types?.forEach(type => {
        filteredProductsObject.push(
          productTypes.find(productType => productType.id === type)
        );
      });

      setFilteredProductTypes(filteredProductsObject);
    } else {
      getDealerDetails(dealerState.parentDealer).then(dealerResult => {
        if (dealerResult?.rank === 1 || currentUser.rank === 1) {
          setFilteredProductTypes(productTypes);
        } else {
          const filteredProductTypesForDealer = productTypes.filter(type =>
            dealerResult?.selected_product_types.includes(type.id)
          );

          setFilteredProductTypes(filteredProductTypesForDealer || []);
        }
      });
    }
  }, [dealerState, selectedProductTypes]);

  /**
   * This function will toggle a supplier in the selectedSuppliers array.
   * If the supplier is already in the array, it will be removed.
   * If the supplier is not in the array, it will be added.
   * @param {Object} supplier - The supplier object to be toggled.
   * @returns {Function} - The function to be called when the supplier is toggled.
   */
  const handleSupplierToggle = supplier => () => {
    const currentIndex = selectedSuppliers.indexOf(supplier.id);
    const newSelectedSuppliers = [...selectedSuppliers];

    if (currentIndex === -1) {
      newSelectedSuppliers.push(supplier.id);
    } else {
      newSelectedSuppliers.splice(currentIndex, 1);
    }

    setSelectedSuppliers(newSelectedSuppliers);
  };

  useEffect(() => {
    if (editing) {
      setSelectedProductTypes(dealerState.selectedProductTypes);
      setSelectedSuppliers(dealerState.selectedSuppliers);
    }
  }, []);

  // This will trigger the change in the main dealer object.

  useEffect(() => {
    onChangeProductTypes(selectedProductTypes);
    onChangeSuppliers(selectedSuppliers);
  }, [selectedProductTypes, selectedSuppliers]);

  /**
   * This useEffect will filter the suppliers based on the selectedProductTypes and
   * will filter the suppliers based on the parentDealer's selected suppliers.
   * If the parentDealer is an object, it will filter the suppliers based on the selected suppliers.
   * If the parentDealer is a number, it will fetch the parentDealer's details and then filter the suppliers.
   * If the parentDealer is not a number or an object, it will not filter the suppliers.
   * @param {Array} products - The list of products.
   * @param {Array} suppliers - The list of suppliers.
   * @param {Object} dealerState - The dealer state object.
   * @param {Array} filteredProductTypes - The filtered product types.
   * @param {number} currentUser - The current user's rank.
   */
  useEffect(() => {
    const filteredProducts = products.filter(product =>
      selectedProductTypes?.includes(product.product_type)
    );

    const supplierNumbers = filteredProducts.map(
      product => product.supplier_id
    );

    // get suppliers that have a supplier number that is in the supplierNumbers array
    const filteredSuppliers = suppliers.filter(supplier =>
      supplierNumbers.includes(supplier.id)
    );

    if (currentUser.rank === 1) {
      setAvailableSuppliers(filteredSuppliers);
      return;
    }

    if (!dealerState.parentDealer) {
      const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
        currentUser.selected_suppliers?.includes(supplier.id)
      );
      setAvailableSuppliers(filteredSuppliersForDealer);
      return;
    }

    if (typeof dealerState.parentDealer === "object") {
      const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
        dealerState.parentDealer.value.selected_suppliers?.includes(supplier.id)
      );
      setAvailableSuppliers(filteredSuppliersForDealer);
    } else {
      getDealerDetails(dealerState.parentDealer).then(dealerResult => {
        setParentSelectedSuppliersState(dealerResult?.selected_suppliers);
        if (dealerResult?.selected_suppliers?.length === 0) {
          setNoParentSupplierNote(
            dealerResult?.selected_suppliers?.length === 0
              ? "Parent Dealer has no suppliers selected"
              : ""
          );
          return;
        }
        const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
          dealerResult?.selected_suppliers.includes(supplier.id)
        );
        setAvailableSuppliers(filteredSuppliersForDealer);
      });
    }
  }, [selectedProductTypes, dealerState, filteredProductTypes]);

  return (
    <div>
      <div style={styles.inputContainer}>
        <Typography>Select Product Types:</Typography>
        <br />
        {filteredProductTypes.length === 0 && (
          <div>
            {!dealerState.parentDealer ? (
              <Typography>
                Please select a product type before selecting a supplier.
              </Typography>
            ) : (
              <Typography>
                Parent Dealer does not have any product types selected.
              </Typography>
            )}
          </div>
        )}
        {filteredProductTypes.map(type => (
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <FormControlLabel
              key={type.id}
              control={
                <Checkbox
                  checked={selectedProductTypes?.includes(type.id)}
                  onChange={handleProductTypeChange}
                  value={type.id}
                />
              }
              label={type.name}
            />
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <Typography>Select Suppliers:</Typography>
        <br />
        {availableSuppliers.length === 0 && (
          <Typography>
            {noParentSupplierNote !== "" ? (
              <Typography color="error">
                *{noParentSupplierNote} please contact admin.
              </Typography>
            ) : (
              "Please select a product type before selecting a supplier."
            )}
          </Typography>
        )}

        {availableSuppliers &&
          availableSuppliers.map(supplier => (
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <FormControlLabel
                key={supplier.id}
                control={
                  <Checkbox
                    checked={selectedSuppliers.indexOf(supplier.id) !== -1}
                    onChange={handleSupplierToggle(supplier)}
                    color="primary"
                  />
                }
                label={supplier.supplier}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

const styles = {
  inputContainer: {
    marginTop: 30,
  },
};
