import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DesktopUserCard from "./DesktopUserCard";
import Top3UserCard from "./Top3UserCard";

export default function StructureDesk({ leaders }) {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [bigCardSize, setBigCardSize] = useState(12);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (leaders === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
      setData(leaders.sort((a, b) => b.sales - a.sales));
    }
    if (leaders.length === 1) {
      setBigCardSize(12);
    } else if (leaders.length === 2) {
      setBigCardSize(6);
    } else if (leaders.length > 2) {
      setBigCardSize(4);
    }
  }, [leaders]);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {loading ? (
        <h1>loading...</h1>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardContent>
              {data[0] ? (
                <Grid container spacing={3}>
                  <Grid item xs={bigCardSize}>
                    <Top3UserCard
                      name={`${
                        `${data[0]?.dealers?.contact_name} ${data[0]?.dealers?.contact_surname}` ||
                        `${!loading ? "No Data" : "Loading..."}`
                      }`}
                      avatar="1"
                      percentage={`${data[0]?.p || "0"}`}
                      fiber={`${data[0]?.sales || "0"}`}
                      rank="https://www.svgrepo.com/show/404692/1st-place-medal.svg"
                      colored="#FFD700"
                      borderColor="#267353"
                    />
                  </Grid>
                  {data[1] ? (
                    <Grid item xs={bigCardSize}>
                      <Top3UserCard
                        name={`${
                          `${data[1]?.dealers?.contact_name} ${data[1]?.dealers?.contact_surname}` ||
                          `${!loading ? "No Data" : "Loading..."}`
                        }`}
                        avatar="1"
                        percentage={`${data[1]?.p || "0"}`}
                        fiber={`${data[1]?.sales || "0"}`}
                        rank="https://www.svgrepo.com/show/404693/2nd-place-medal.svg"
                        colored="#C0C0C0"
                        borderColor="#267353"
                      />
                    </Grid>
                  ) : null}{" "}
                  {data[2] ? (
                    <Grid item xs={bigCardSize}>
                      <Top3UserCard
                        name={`${
                          `${data[2]?.dealers?.contact_name} ${data[2]?.dealers?.contact_surname}` ||
                          `${!loading ? "No Data" : "Loading..."}`
                        }`}
                        avatar="1"
                        percentage={`${data[2]?.p || "0"}`}
                        fiber={`${data[2]?.sales || "0"}`}
                        rank="https://www.svgrepo.com/show/404695/3rd-place-medal.svg"
                        colored="#CD7F32"
                        borderColor="#267353"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <Typography align="center" variant="h5">
                  {" "}
                  "Kindly revisit later as there are currently no sales recorded
                  in your structure at the moment."
                </Typography>
              )}
            </CardContent>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {data.slice(3, 10).map((Leader, index) => {
                    return (
                      <Grid item xs={12}>
                        <DesktopUserCard
                          rank={index + 4}
                          avatar="https://mui.com//static/images/avatar/4.jpg"
                          name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                          percentage={Leader.p}
                          fiber={Leader.sales}
                        />
                      </Grid>
                    );
                  })}{" "}
                  {data.length > 10 ? (
                    <Grid item xs={12}>
                      <Card
                        style={{
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for contained buttons
                          transition:
                            "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
                        }}
                        onMouseEnter={e => {
                          e.currentTarget.style.transform = "scale(1.02)";
                          e.currentTarget.style.boxShadow =
                            "0px 6px 15px rgba(0, 0, 0, 0.2)";
                        }}
                        onMouseLeave={e => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow =
                            "0px 4px 12px rgba(0, 0, 0, 0.1)";
                        }}
                      >
                        <Button
                          fullWidth
                          onClick={() => handleExpand()}
                          style={{
                            padding: 15,
                          }}
                        >
                          <Typography
                            align="center"
                            variant="h3"
                            color="primary"
                          >
                            Expand
                            {!expanded ? (
                              <KeyboardArrowDown
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            ) : (
                              <KeyboardArrowUp
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            )}
                          </Typography>
                        </Button>
                      </Card>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {expanded
                    ? data.slice(10, 100).map((Leader, index) => {
                        return (
                          <Grid item xs={12}>
                            <DesktopUserCard
                              rank={index + 10}
                              avatar="https://mui.com//static/images/avatar/4.jpg"
                              name={`${data[1]?.dealers?.contact_name} ${data[1]?.dealers?.contact_surname}`}
                              percentage={Leader.p}
                              fiber={Leader.sales}
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
