import { createMuiTheme, createTheme } from "@material-ui/core/styles";
import zawadiLogo from "../img/ZawadiHighRes.gif";

const chadezTheme = createTheme({
  palette: {
    primary: {
      main: "#126289",
      contrastText: "#fff",
    },
  },
});

const jurassicTheme = createTheme({
  palette: {
    primary: {
      main: "#a53922",
      contrastText: "#fff",
    },
  },
});

const afrahTheme = createTheme({
  palette: {
    primary: {
      main: "#102b56",
      contrastText: "#fff",
    },
  },
});

const zawadiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1b2b4a", // Main color
      contrastText: "#fff",
      dark: "#0c0e1e", // Darker shade for primary
    },
    secondary: {
      main: "#e68625", // Main color
      dark: "#b55e19", // Darker shade for secondary
    },
    background: {
      default: "#1b2b4a",
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: "10px", // Set the button rounding
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for contained buttons
        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
        "&:hover": {
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
          transform: "scale(1.05)", // Slight scaling on hover
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: "16px", // Set the card rounding
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
      },
    },
  },
});
const companies = {
  zawadi: {
    theme: zawadiTheme,
    logo: zawadiLogo,
  },
};
export { companies, afrahTheme, chadezTheme, jurassicTheme, zawadiTheme };

// 60 -30 -10 color scheme
// 60 #31a79b
// 30 #e68625
// 10 #101628
